import './styles/main.scss';

import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { initGA } from '@yojee/helpers/GAHelper';
import { HistoryRouter } from '@yojee/ui/router/Router';

import packageJson from '../../../package.json';
import Layout from './components/Common/Layout';
import ItemTracking from './components/ItemTracking';
import OrderTracking from './components/OrderTracking';

window.appVersion = window.appVersion || packageJson.version;

initGA('tracking', window.appVersion);

const App = ({ history }) => (
  <HistoryRouter history={history}>
    <Suspense fallback={<div />}>
      <Routes>
        <Route path="" element={<Layout />}>
          <Route path="/order/:orderTrackingNumber" element={<OrderTracking />} />
          <Route path="/item/:itemTrackingNumber" element={<ItemTracking />} />
        </Route>
      </Routes>
    </Suspense>
  </HistoryRouter>
);

export default App;
