import '../styles/components/orderTracking.scss';

import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useFeatureTracker from '@yojee/ui/event-tracking/hooks/useFeatureTracker';
import { MapController } from '@yojee/ui/map';

import { getTaskTypes } from '../sagas/company/companyActions';
import * as orderActions from '../sagas/order/orderActions';
import Item from './Item';
import ItemAccordion from './Item/ItemAccordion';
import OriginDestination from './OriginDestination';

const OrderTracking = () => {
  useFeatureTracker('order_tracking', true);
  const dispatch = useDispatch();
  const { orderTrackingNumber } = useParams();

  useEffect(() => {
    if (orderTrackingNumber) {
      dispatch(orderActions.getOrderDetails(orderTrackingNumber));
      MapController.monitorDriverMovement();
      dispatch(getTaskTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTrackingNumber]);

  const orderData = useSelector((state) => state.order?.orderData);
  const failed = useSelector((state) => state.order?.error?.failed);
  const adminAccess = useSelector((state) => state.company?.companyInfo?.admin_access);

  const orderItems = orderData['order_items'];
  const isItemLogShown = orderItems?.length === 1;

  return (
    <div className="order-details-container" data-cy="tracking-order-details-container">
      {failed ? (
        <div className="invalid-tracking-number-container" data-cy="tracking-invalid-number">
          <h2>
            <Trans>Invalid Tracking Number</Trans>.
          </h2>
          <div className="text">
            <Trans>Please check your tracking number and try again</Trans>.
          </div>
        </div>
      ) : orderData['number'] && orderItems.length > 0 ? (
        <>
          <div className="page-subtitle">
            <span className="tracking-number" data-cy="tracking-order-number">
              <span className="label">
                <Trans>Order Tracking Number</Trans>:&nbsp;
              </span>
              <span className="value">
                {adminAccess['items.show.external_customer_id']
                  ? orderData['external_id'] || orderData['number']
                  : orderData['number']}
              </span>
            </span>
          </div>
          <OriginDestination orderItems={orderItems} />
          <div className="item-details-container" data-cy="tracking-all-items-details">
            {orderItems.length === 1 ? (
              <Item
                key={`item-form-${orderItems[0]['item_id']}`}
                index="0"
                item={orderItems[0]}
                isItemTrackingNumberShown={true}
                isItemLogShown={true}
                isItemDetailsShown={true}
              />
            ) : (
              orderItems.map((item, index) => {
                if (item?.item_id) {
                  return (
                    <ItemAccordion
                      key={`item-form-${item['item_id']}`}
                      index={index}
                      item={item}
                      isItemTrackingNumberShown={true}
                      isItemLogShown={isItemLogShown}
                      adminAccess={adminAccess}
                    />
                  );
                }
                return null;
              })
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default OrderTracking;
