import { call, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
import i18next from 'i18next';

import { CommonService, commonService } from '../../services/commonService';
import { companyService } from '../../services/companyService';

export const companyStoreData = (state) => state.company;

export default function* sagas() {
  yield takeLatest('REQUEST_GET_COMPANY_INFO', getCompanyInfo);
  yield takeLatest('REQUEST_GET_COMPANY_SERVICE_TYPES', getCompanyServiceTypes);
  yield takeEvery('REQUEST_GET_JWT_TOKEN', getJwtToken);
  yield takeLatest('REQUEST_GET_TASK_TYPES', getTaskTypes);
}

function* getCompanyInfo() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getCompanyInfo' });
    const slug = yield call(CommonService.getSlugFromUrl);
    yield put({ type: 'GET_COMPANY_SLUG_SUCCESSFUL', slug });
    const documentServiceHost = yield call(commonService.getDocumentServiceHost);
    yield put({ type: 'GET_DODUMENT_SERVICE_HOST_SUCCESSFUL', documentServiceHost });
    if (slug) {
      yield put({ type: 'START_LOADING', loadingAction: 'getCompanyInfo' });
      const companyData = yield call(companyService.getCompanyInfo, slug);
      if (companyData?.sender?.['live_tracking.language']) {
        i18next.changeLanguage(companyData.sender['live_tracking.language']);
      }
      yield put({ type: 'GET_COMPANY_INFO_SUCCESSFUL', companyData });
      //	set company branding style
      if (companyData.branding) {
        CommonService.setFavicon(companyData.branding.favicon);
        const companyThemeStyle = yield call(companyService.getCompanyBrandingStyle, companyData.branding);
        if (companyThemeStyle) {
          yield put({
            type: 'SET_COMPANY_BRANDING_STYLE',
            branding: {
              style: companyThemeStyle,
            },
          });
        }
      }

      //	get company service types
      yield put({ type: 'REQUEST_GET_COMPANY_SERVICE_TYPES' });
    } else {
      yield put({
        type: 'GET_COMPANY_INFO_FAILED',
        error: {
          failed: true,
          message: 'No company slugs found',
        },
      });
    }
  } catch (error) {
    yield put({ type: 'GET_COMPANY_INFO_FAILED', error });
  }
}

function* getCompanyServiceTypes(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getServiceTypes' });
    const { companyInfo } = yield select(companyStoreData);
    if (companyInfo && companyInfo.slug) {
      const { slug } = companyInfo;
      const data = yield call(companyService.getCompanyServiceTypes, slug);
      yield put({ type: 'GET_COMPANY_SERVICE_TYPES_SUCCESSFUL', data });
    }
  } catch (error) {
    yield put({ type: 'GET_COMPANY_SERVICE_TYPES_FAILED', error });
  }
}

function* getJwtToken(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getJwtToken' });
    const data = yield call(companyService.getJwtToken, action.payload);
    yield put({ type: 'GET_JWT_TOKEN_SUCCESSFUL', data, trackingNumber: action.payload });
  } catch (error) {
    yield put({ type: 'GET_JWT_TOKEN_FAILED', error, trackingNumber: action.payload });
  }
}

function* getTaskTypes() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getTaskTypes' });
    const slug = yield call(CommonService.getSlugFromUrl);
    const data = yield call(companyService.getTaskTypes, slug);
    yield put({ type: 'GET_TASK_TYPES_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_TASK_TYPE_FAILED', error });
  }
}
