import { isDate, isNull, isString } from './validators';

export function getPropertyValue(target, props) {
  let _prop;

  for (let i = 0; i < props.length; i++) {
    _prop = i === 0 ? target[props[i]] : _prop[props[i]];

    if (isNull(_prop)) {
      break;
    }
  }

  return _prop;
}
// sortType: 'asc' | 'desc'
export function sortArray(target, sortBy, sortType) {
  return target.sort((item1, item2) => {
    const _order = sortType === 'desc' ? -1 : 1;
    const _item1Value = getPropertyValue(item1, sortBy.split('.'));
    const _item2Value = getPropertyValue(item2, sortBy.split('.'));

    if (isNull(_item1Value)) {
      return 1 * _order;
    }
    if (isNull(_item2Value)) {
      return -1 * _order;
    }
    if (isString(_item1Value)) {
      return _item1Value.localeCompare(_item2Value) * _order;
    }
    if (isDate(_item1Value)) {
      return (_item1Value.getTime() - _item2Value.getTime()) * _order;
    }

    return (_item1Value - _item2Value) * _order;
  });
}

export function isObjectEmpty(object) {
  return (
    object === null ||
    object === undefined ||
    object.length === 0 ||
    object.toString().trim() === '' ||
    (Object.keys(object).length === 0 && object.constructor === Object)
  );
}

export const convertToKebabCase = (string) => {
  return string.replace(/\s+/g, '-').toLowerCase();
};
