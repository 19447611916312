import { isPossiblePhoneNumber } from 'libphonenumber-js';

import { authUtilsAdapter } from './AuthUtilsAdapter';

/* eslint-disable no-useless-escape */
export function isString(target) {
  return target && typeof target === 'string';
}

export function isNull(target) {
  return target === undefined || target === null;
}

export function isValidEmail(email) {
  const EMAIL_REGEX =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return EMAIL_REGEX.test(email);
}

export function isValidPhoneNumber(phoneNumber, region) {
  return isPossiblePhoneNumber(phoneNumber, region);
}

export function isEmpty(target, noWhiteSpace = false, noZero = false) {
  return (
    isNull(target) ||
    target === '' ||
    (noWhiteSpace ? isString(target) && target.trim().length === 0 : false) ||
    (noZero ? target === 0 : false)
  );
}

export function isValidPassword(password) {
  return !!password && password.length >= 8;
}

export function isLowerCase(target) {
  return /^(.*[a-z].*)/g.test(target);
}

export function isUpperCase(target) {
  return /^(.*[A-Z].*)/g.test(target);
}

export function isIncludeNumber(target) {
  return /^(.*[0-9].*)/i.test(target);
}

export function isIncludeSpecialChars(target) {
  return /^(?=.*[@!#\$%&()>\\\*\~\|\}\{\_\^\?\+\-\=\[\],[\]\/])/i.test(target);
}

export function getSlugFromUrl() {
  return authUtilsAdapter.getSlugFromUrl();
}

export function slugToText(slug) {
  return `${slug.charAt(0).toUpperCase()}${slug.slice(1).replace('-', ' ')}`;
}

export function isNumber(value) {
  return /^[0-9]+$/.test(value);
}

export function phoneValidation(value) {
  value = value.split(' ').join('');
  return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
}
