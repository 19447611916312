import { isObjectEmpty } from '../infrastructure';
import { authService } from './authService';
import { BaseService } from './baseService';

export class CompanyService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getCompanyInfo = (slug) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/company_info'), {
        company_slug: slug,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyServiceTypes = (slug) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/service_types'), {
        company_slug: slug,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : { serviceTypes: [], nonOperatingDates: [] };
      })
      .catch((error) => this.handleError(error));
  };

  getTaskTypes = (slug) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/task_type_mappings'), { company_slug: slug })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : [];
      })
      .catch((error) => this.handleError(error));
  };

  getJwtToken = (trackingNumber) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('public/jwt/delivery_tracker'), {
        tracking_number: trackingNumber,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getCompanyBrandingStyle = (brandingData) => {
    if (isObjectEmpty(brandingData) || !brandingData['admin_primary_color'] || !brandingData['background_color']) {
      return null;
    }
    return {
      'admin-primary-color': {
        backgroundColor: brandingData['admin_primary_color'],
        borderColor: brandingData['admin_primary_color'],
      },
      'branding-background-color': {
        backgroundColor: brandingData['background_color'],
        borderColor: brandingData['background_color'],
      },
    };
  };
}

export const companyService = new CompanyService({ authService });
