import { useCallback, useEffect } from 'react';

import { sendGA4EventSync } from '@yojee/helpers/GAHelper';

const useFeatureTracker = (feature, trackOnRender = false) => {
  const callback = useCallback(
    (event, extraParams = {}, value = null) => {
      sendGA4EventSync({ feature, event, value, ...extraParams });
    },
    [feature]
  );

  useEffect(() => {
    if (trackOnRender) {
      sendGA4EventSync({ feature, event: 'opened' });
    }
  }, [trackOnRender, feature]);

  return { sendEvent: callback };
};

export default useFeatureTracker;
