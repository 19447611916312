import { AddressComponents, OrderTrackingItemStep } from '../../types';

function getFullAddress(addressComponents?: AddressComponents): string {
  if (addressComponents) {
    let fullAddress = addressComponents.address;
    if (addressComponents.postal_code) {
      fullAddress += ` ${addressComponents.postal_code}`;
    }
    if (addressComponents.country) {
      fullAddress += ` ${addressComponents.country.toUpperCase()}`;
    }
    return fullAddress;
  } else {
    return '';
  }
}

export function getOriginAndDestinationAddress(orderItemSteps: OrderTrackingItemStep[]) {
  let [originStep, destinationStep]: Array<OrderTrackingItemStep | undefined> = orderItemSteps;

  if (orderItemSteps.length > 2) {
    originStep = orderItemSteps.find((step) => step.step_property === 'origin');
    destinationStep = orderItemSteps.find((step) => step.step_property === 'destination');
  }

  return {
    originAddress: getFullAddress(originStep?.address_components),
    destinationAddress: getFullAddress(destinationStep?.address_components),
  };
}
