import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isSenderInfoAndAddressShownSelector } from '../../sagas/company/companySelectors';
import destinationIcon from '../../styles/assets/img/destinationIcon.png';
import originIcon from '../../styles/assets/img/originIcon.png';
import { OrderItem } from '../../types';
import { getOriginAndDestinationAddress } from './get-origin-and-destination-address';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '5px',
    paddingBottom: '15px',

    '@media (max-width:950px)': {
      paddingLeft: '16px',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '21px',
  },
  originIcon: {
    width: '16px',
    height: '16px',
    marginRight: '11px',
    '@media (max-width:950px)': {
      marginRight: '5px',
    },
  },
  destinationIcon: {
    width: '24px',
    height: '24px',
    marginRight: '7px',
    marginLeft: '-4px',
    '@media (max-width:950px)': {
      marginRight: '1px',
    },
  },
  label: {
    fontWeight: 'bold',
  },
  address: {
    fontSize: '14px',
    marginLeft: '5px',
  },
}));

interface Props {
  orderItems: OrderItem[];
}

const OriginDestination = ({ orderItems }: Props) => {
  const classes = useStyles();
  const orderItemSteps = orderItems[0].order_item_steps;
  const { originAddress, destinationAddress } = getOriginAndDestinationAddress(orderItemSteps);
  const isSenderInfoAndAddressShown = useSelector(isSenderInfoAndAddressShownSelector);

  return (
    <div className={classes.container}>
      {isSenderInfoAndAddressShown && (
        <div className={classes.row}>
          <img src={originIcon} alt="origin" className={classes.originIcon} />
          <span className={classes.label}>
            <Trans>Origin</Trans>
          </span>{' '}
          : <span className={classes.address}>{originAddress}</span>
        </div>
      )}

      <div className={classes.row}>
        <img src={destinationIcon} alt="destination" className={classes.destinationIcon} />
        <span className={classes.label}>
          <Trans>Destination</Trans>
        </span>{' '}
        : <span className={classes.address}>{destinationAddress}</span>
      </div>
    </div>
  );
};

export default OriginDestination;
