import {
  localEnvsAtRuntimeLocalStorageKey,
  updateEnvsFromStaticFile,
} from '@yojee/helpers/env/updateEnvsFromStaticFile';

let localEnvsAtRuntime = {};
const isLocal = process.env.REACT_APP_IS_LOCAL === 'true';

if (isLocal) {
  updateEnvsFromStaticFile().then();
  localEnvsAtRuntime = JSON.parse(localStorage.getItem(localEnvsAtRuntimeLocalStorageKey) || '{}');
}

/**
 * This wrapper is used to get the environment variables.
 * Use this to have ability to override the environment variables at runtime
 * Especially useful in development, no need to change .env file and restart.
 * @param envName
 * @returns {*}
 */
export default function getEnv(envName) {
  if (isLocal) {
    if (localEnvsAtRuntime[envName] !== undefined) {
      return localEnvsAtRuntime[envName];
    }

    return process.env[envName];
  }

  return process.env[envName];
}
