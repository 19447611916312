import { getEnv } from '@babel/core';

import packageJson from '../../../../../package.json';

const LAST_GIT_VERSION = getEnv('REACT_LAST_GIT_VERSION');
const version = packageJson.version;
const ENV = process.env.REACT_APP_ENV;
const SOLVER_URL = process.env.REACT_APP_SOLVER_URL;
const GEO_URL = process.env.REACT_APP_GEO_API_URL;
const BUCKET_URL = 's3.ap-southeast-1.amazonaws.com';

export const addVersionHeader = (config) => {
  const isValidEndpoint =
    config?.url &&
    [BUCKET_URL, SOLVER_URL, GEO_URL].filter((url) => url).every((url) => config.url.indexOf(url) === -1);

  if (isValidEndpoint) {
    config.headers['X-API-USER-AGENT'] = 'Web/' + (ENV === 'production' ? version : LAST_GIT_VERSION);
  }
};
