import '../../styles/components/Common/header.scss';

import { Component } from 'react';
import { connect } from 'react-redux';

class Header extends Component {
  render() {
    const { branding } = this.props;
    const _logo = branding['logo'] ? branding['logo'] : null;
    const _backgroundColor = branding['background_color'] ? branding['background_color'] : null;
    return (
      <div className="header-container" style={{ backgroundColor: _backgroundColor }}>
        <div className="header">
          <span className="logo" style={{ backgroundImage: `url(${_logo})` }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branding: state.company.companyInfo.branding,
  };
};

export default connect(mapStateToProps)(Header);
