const defaultState = {
  orderData: {
    cancelled_at: null,
    inserted_at: null,
    order_items: [],
    status: null,
    container_no: null,
    description: null,
    external_id: null,
    number: null,
    price: null,
  },
  inProgress: {
    getOrderDetails: false,
    getItemDetails: false,
  },
  error: {
    failed: false,
    message: null,
  },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  GET_ORDER_DETAILS_SUCCESSFUL: (state, sagaData) => {
    if (sagaData.data) {
      return {
        ...state,
        orderData: sagaData.data,
        inProgress: {
          ...state.inProgress,
          getOrderDetails: false,
        },
      };
    }
    return state;
  },
  GET_ORDER_DETAILS_FAILED: (state, sagaData) => {
    if (sagaData && sagaData.error) {
      const { error } = sagaData;
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          getOrderDetails: false,
        },
        error: {
          failed: true,
          message: error?.message ?? 'Failed to get order details',
        },
      };
    }
    return state;
  },
  GET_ITEM_DETAILS_SUCCESSFUL: (state, sagaData) => {
    if (sagaData.data) {
      return {
        ...state,
        orderData: sagaData.data,
        inProgress: {
          ...state.inProgress,
          getItemDetails: false,
        },
      };
    }
    return state;
  },
  GET_ITEM_DETAILS_FAILED: (state, sagaData) => {
    if (sagaData && sagaData.error) {
      const { error } = sagaData;
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          getItemDetails: false,
        },
        error: {
          failed: true,
          message: error?.message ?? 'Failed to get item',
        },
      };
    }
  },

  GET_RESET_ERRORS: (state, sagaData) => {
    return {
      ...state,
      error: { ...defaultState.error },
    };
  },
  ORDER_IN_PROGRESS: (state, { key }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        [key]: true,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
