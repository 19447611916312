import ReactGA from 'react-ga4';

import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';
import { logData } from '@yojee/helpers/LogHelper';

import { getCurrentUser } from './userHelper';

export const initGA = (source, version) => {
  if (process.env.REACT_APP_GA_V4_ID) {
    const options = {
      gtagOptions: {
        app_id: source,
        app_name: source,
        app_version: version,
        custom_map: { dimension1: 'slug' },
      },
    };

    ReactGA.initialize(process.env.REACT_APP_GA_V4_ID, options);
    ReactGA.set({ user_properties: { slug: getSlugFromUrl() } });
  }
};

export function sendGA4EventSync({ feature, event, value = null, ...extraParams }) {
  logData({ module: 'GAHelper', message: 'event retrieved', data: { feature, event, value } });
  if (process.env.REACT_APP_GA_V4_ID && feature) {
    const { name } = getCurrentUser() ?? {};
    event = event || 'opened';

    const fieldObject = {
      event_action: event,
      event_category: feature,
      hit_type: 'event',
      slug: getSlugFromUrl(),
      user_name: name,
      ...extraParams,
    };

    if (value !== null) {
      fieldObject.value = value;
    }

    ReactGA.event(event, fieldObject);
    ReactGA.set({ userId: name });
  }
}
