class DefaultServiceRunner {
  getSlugFromUrl = () => {
    if (window) {
      const parts = window.location.hostname.split('.');
      return parts[1] &&
        ['test', 'localhost', 'dispatcher', 'dispatch', 'scan', 'manage', 'explore'].some((val) =>
          parts[1].includes(val)
        )
        ? parts[0]
        : 'yojee';
    }

    return null;
  };
}

/**
 * This class use for change implementation of some functions which can differ in difference apps
 */
class AuthUtilsAdapter {
  runner = new DefaultServiceRunner();

  setRunner = (runner) => {
    this.runner = runner;
  };

  getSlugFromUrl = () => {
    return this.runner.getSlugFromUrl();
  };
}

export const authUtilsAdapter = new AuthUtilsAdapter();
