import { BaseService } from './baseService';

export class CommonService extends BaseService {
  getDocumentServiceHost = () => {
    return this._getUmbrellaPublicHost();
  };

  static getSlugFromUrl = () => {
    if (window) {
      const parts = window.location.hostname.split('.');
      if (parts[1] && parts[1] === 'tadaa' && parts[2] && parts[2] === 'express') {
        return 'tadaaexpress';
      }
      return parts[1] && ['localhost', 'track', 'test', 'tracking'].some((val) => parts[1].includes(val))
        ? parts[0]
        : 'yojee';
    }

    return null;
  };

  static setFavicon = (favicons) => {
    const defaultFavicon = document.querySelector("link[rel*='icon']");
    const head = document.getElementsByTagName('head')[0];
    if (defaultFavicon) {
      head.removeChild(defaultFavicon);
    }
    Object.keys(favicons).forEach((sizes) => {
      const link = document.createElement('link');
      link.type = 'image/png';
      link.rel = 'shortcut icon';
      link.href = favicons[sizes];
      link.setAttribute('sizes', sizes);
      head.appendChild(link);
      return null;
    });
  };

  static getExploreUrl = (rest = '') => {
    const EXPLORE_URL = window.location.origin
      .replace(/track/, 'dispatcher')
      // For eks
      .replace(/tracking/, 'explore');

    const slug = CommonService.getSlugFromUrl();
    return slug && !EXPLORE_URL.includes(`://${slug}.`)
      ? `${EXPLORE_URL}/${rest}`.replace('://', `://${slug}.`)
      : `${EXPLORE_URL}/${rest}`;
  };
}

export const commonService = new CommonService({});
