import { call, put, takeLatest } from '@redux-saga/core/effects';

import { orderService } from '../../services/orderService';

export const orderStoreData = (state) => state.order;

export default function* sagas() {
  yield takeLatest('REQUEST_GET_ORDER_DETAILS', getOrderDetails);
  yield takeLatest('REQUEST_GET_ITEM_DETAILS', getItemDetails);
  yield takeLatest('RESET_ERRORS', resetErrors);
}

function* getOrderDetails(action) {
  try {
    yield put({ type: 'ORDER_IN_PROGRESS', key: 'getOrderDetails' });
    const data = yield call(orderService.getOrderDetails, action.payload);
    yield put({
      type: 'GET_ORDER_DETAILS_SUCCESSFUL',
      data,
    });
  } catch (error) {
    yield put({ type: 'GET_ORDER_DETAILS_FAILED', error });
  }
}

function* getItemDetails(action) {
  try {
    yield put({ type: 'ORDER_IN_PROGRESS', key: 'getItemDetails' });
    const data = yield call(orderService.getItemDetails, action.payload);
    yield put({ type: 'GET_ITEM_DETAILS_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_ITEM_DETAILS_FAILED', error });
  }
}

function* resetErrors() {
  yield put({ type: 'GET_RESET_ERRORS' });
}
