import './index.css';

import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import i18n from '@yojee/helpers/i18nLoader';

import App from './App';
import createRootReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

export const store = configureStore({
  reducer: createRootReducer(routerReducer),
  middleware: [sagaMiddleware, routerMiddleware],
  devTools: process.env.NODE_ENV === 'development',
});

export const history = createReduxHistory(store);

sagaMiddleware.run(sagas);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
