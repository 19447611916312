import { dispatcherAuthService } from './dispatcherAuthService';

/**
 * This class allow we have ability change auth logic. Because dispatcher and sender have difference auth logic
 */
class AuthServiceWrapper {
  constructor({ provider }) {
    this.provider = provider;
  }

  setProvider = (newAuthService) => {
    this.provider = newAuthService;
  };

  get = (url, params, options) => this.provider.get(url, params, options);
  post = (url, body, options) => this.provider.post(url, body, options);
  put = (url, body, options) => this.provider.put(url, body, options);
  patch = (url, body, options) => this.provider.patch(url, body, options);
  delete = (url, options) => this.provider.delete(url, options);
}

export const authService = new AuthServiceWrapper({ provider: dispatcherAuthService });
