/**
 * New error format
 * Example errors: [
 {
        "code": "EC401",
        "message": "payload_type can't be blank",
        "metadata": {
            "data_entity": "order_item",
            "field": "payload_type",
            "ref": {
                "col": 3,
                "row": 4
            },
            "value": "Package"
        }
    },
 {
        "code": "EC401",
        "message": "address can't be blank",
        "metadata": {
            "data_entity": "order_step",
            "field": "address",
            "ref": [
                {
                    "col": 9,
                    "row": 4
                }
            ],
            "value": null
        }
    },
 {
        "code": "EC403",
        "message": "location is invalid",
        "metadata": {
            "data_entity": "order_step",
            "field": "location",
            "ref": [
                {
                    "col": null,
                    "row": 4
                }
            ],
            "value": null
        }
    }
 ]
 * @param errors
 */
export const fromErrorsToMessages = (errors) => {
  const formattedErrors = getErrorsInClearStructure(errors);
  return formattedErrors.map((error) => fromErrorToMessage(error)) ?? [];
};

export function fromErrorToMessage(error, fieldsSchema) {
  const rowMessage = error.row ? `Row ${error.row}` : '';
  const columnMessage = error.col ? `Column ${error.col}` : '';
  let errorMessage = '';

  if (rowMessage) {
    errorMessage = rowMessage;

    if (columnMessage) {
      errorMessage = `${rowMessage}, ${columnMessage}`;
    }

    errorMessage = `${errorMessage}: ${correctErrorMessageWithTemplateFieldsSchema(error, fieldsSchema)}`;
  }

  return errorMessage || error?.message;
}

/**
 * Replace field key in error message by name of field in template
 * @param error
 * @param fieldsSchema
 * @returns {*}
 */
export function correctErrorMessageWithTemplateFieldsSchema(error, fieldsSchema) {
  let message = error.message;
  const schemaKey = `${error.metadata?.data_entity}_${error.field}`;
  const fieldSchema = fieldsSchema?.[schemaKey];

  if (fieldSchema && message) {
    message = message.replace(error.field, fieldSchema.name);
  }

  return message;
}

/**
 *
 * @param errors - errors from API
 * @returns [{ row, col, message, field, metadata, code }]
 */
export const getErrorsInClearStructure = (errors) => {
  if (!errors?.length) {
    return [];
  }

  const result = [];
  errors.forEach((error) => {
    const ref = error.metadata?.ref || {};
    if (Array.isArray(ref)) {
      ref.forEach((r) => {
        result.push({
          row: r.row,
          col: r.col,
          field: error.metadata?.field,
          metadata: error.metadata,
          message: error.message,
          code: error.code,
        });
      });
    } else {
      result.push({
        row: ref.row,
        col: ref.col,
        field: error.metadata?.field,
        metadata: error.metadata,
        message: error.message,
        code: error.code,
      });
    }
  });

  return result
    .sort((error1, error2) => {
      if (error1.row === undefined || error1.row === null) return 1;
      else if (error1.row > error2.row) return 1;
      else if (error1.row < error2.row) return -1;
      else if (error1.col === undefined || error1.col === null || error1.col === '') return 1;
      else if (error1.col > error2.col) return 1;
      else if (error1.col < error2.col) return -1;

      return 0;
    })
    .map((error) => ({
      ...error,
      col: fromColumnNumbericToAlphabet(error.col),
    }));
};

function fromColumnNumbericToAlphabet(number) {
  let columnName = '',
    columnNumber = number;

  while (columnNumber > 0) {
    const modulo = (columnNumber - 1) % 26;
    columnName = String.fromCharCode(65 + modulo) + columnName;
    columnNumber = Math.floor((columnNumber - modulo) / 26);
  }

  return columnName;
}

export const ERROR_CODES = {
  dateInThePast: 'BK0513',
  noMatchingRateCard: 'BK0588',
};
