import getEnv from '@yojee/helpers/env/getEnv';

const UMBRELLA_HOST = getEnv('REACT_APP_UMBRELLA_HOST_URL');
const ENV = process.env.REACT_APP_ENV;
console.log(`api url: ${UMBRELLA_HOST}`);

export class BaseService {
  getUmbrellaApiUrl(url) {
    return `${UMBRELLA_HOST}/api/v3/${url}`;
  }

  _getUmbrellaPublicHost() {
    return `${UMBRELLA_HOST}/api/v3/public`;
  }

  _getEnv() {
    return ENV;
  }

  extractData(response) {
    return response && response['data'] ? response['data'] : response;
  }

  handleError = (error) => {
    const { response } = error;
    const errorObj = {
      statusCode: response.status ? response.status : null,
      message: null,
      statusText: response.statusText ? response.statusText : null,
      errors: [],
    };

    if (response['data']) {
      const { data } = response;
      if (data['failed_operation'] && data['failed_value']) {
        errorObj.message = data['failed_value'];
      } else if (data['message']) {
        errorObj.message = data['message'];
      } else if (data['data']) {
        const _data = data['data'];
        if (Array.isArray(_data) && _data.length > 0) {
          const _errorMessages = Object.keys(_data[0]).map((key) => _data[0][key][0]);
          errorObj.message = _errorMessages && _errorMessages.length > 0 ? _errorMessages[0] : '';
        } else {
          errorObj.message = Object.keys(_data).map((key) => _data[key][0])[0];
        }
      }

      if (data['data'] && data['data']['errors'] && data['data']['errors'].length > 0) {
        errorObj.errors = data['data']['errors'];
        if (data['data']['missing_info_count']) {
          errorObj.missingInfoCount = data['data']['missing_info_count'];
        }
        if (data['data']['total_items']) {
          errorObj.totalItemCount = data['data']['total_items'];
        }
      }
    }
    throw errorObj;
  };
}
