import Axios from 'axios';
import moment from 'moment';

import { clearJwtData, decodeJwtData, saveJwtData } from '@yojee/auth/jwt-storage';
import { LocalStorage } from '@yojee/local-storage/local-storage';

import { BaseService } from '../baseService/index';
import { addVersionHeader } from '../common/versionHeaderHelper';

const axiosInstance = Axios.create();

const getNewJwt = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`${BaseService.getUmbrellaApiHostUrl()}/public/jwt/auth_token`, {
        refresh_token: LocalStorage.getItem('refresh_token'),
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // process and store JWT data in LocalStorage
          const decodedJwtData = decodeJwtData(res.data);
          saveJwtData(decodedJwtData);
          resolve({ ...res.data, permissions_updated_at: decodedJwtData.permissions_updated_at });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//	add interceptors for axios request/response to handle jwt
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      !['public/', 'auth/'].some((key) => error?.response?.config?.url.indexOf(key) > -1)
    ) {
      return Promise.reject({
        ...error,
        ...{
          response: {
            ...error.response,
            message: null,
          },
        },
      });
    } else {
      return Promise.reject(error);
    }
  }
);

axiosInstance.interceptors.request.use(
  async (config) => {
    addVersionHeader(config);

    //	for public APIs and APIs involving `auth`, no need to include access token
    if (
      ['public/', 'auth/', 's3.ap-southeast-1.amazonaws.com'].some((key) => config.url && config.url.indexOf(key) > -1)
    ) {
      return config;
    }

    //	if the header already has 'Authorization' value (i.e. in the case of partner_jwt or tracking_jwt)
    //	proceed with the API call without checking for expiry time
    if (config.headers && config.headers['Authorization']) {
      return config;
    }

    const accessToken = LocalStorage.getItem('access_token');
    const accessTokenExpiry = LocalStorage.getItem('access_token_expiry');
    const refreshTokenExpiry = LocalStorage.getItem('refresh_token_expiry');
    const _permissionsUpdatedAt = LocalStorage.getItem('permissions_updated_at');

    const timeNow = new Date().getTime() / 1000;
    //	if refresh token is about to expire,
    //	or if access_token or access_token_expiry arent found in localStorage,
    //	cancel API call and log user out
    if (!accessToken || !accessTokenExpiry || (refreshTokenExpiry && refreshTokenExpiry - 2 * 60 < timeNow)) {
      //	if refresh token is expired, clear JWT data and notify Explore to log out
      clearJwtData();
      window.parent.postMessage(
        {
          type: 'REFRESH_TOKEN_EXPIRED',
        },
        '*'
      );

      //	cancel the request
      return config;
    }

    //	refresh access token 2 minutes before token is expired
    if (accessTokenExpiry && accessTokenExpiry - 2 * 60 < timeNow) {
      if (config.url && config.url.indexOf('/public/jwt/auth_token') === -1) {
        try {
          const data = await getNewJwt();
          const token = data && data['access_token'];

          if (
            data &&
            data['permissions_updated_at'] &&
            (!_permissionsUpdatedAt || moment(_permissionsUpdatedAt).isBefore(moment(data['permissions_updated_at'])))
          ) {
            //	if there is permissions update log out the user
            clearJwtData();
            window.parent.postMessage(
              {
                type: 'REFRESH_TOKEN_EXPIRED',
              },
              '*'
            );

            //	cancel the request
            return config;
          }
          config.headers['Authorization'] = `Bearer ${token}`;
        } catch (error) {
          Promise.reject(error);
          window.location.href = '/login';
        }
      }
      return config;
    }

    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const APIAxiosInstance = axiosInstance;

export class DispatcherAuthService extends BaseService {
  get = (url, params, options) => {
    return axiosInstance.get(url, { params, ...this.createAuthRequestConfig(options) });
  };
  post = (url, body, options) => {
    return axiosInstance.post(url, body, this.createAuthRequestConfig(options));
  };
  put = (url, body, options) => {
    return axiosInstance.put(url, body, this.createAuthRequestConfig(options));
  };
  patch = (url, body, options) => {
    return axiosInstance.patch(url, body, this.createAuthRequestConfig(options));
  };
  delete = (url, options) => {
    const params = options && options.params;
    return axiosInstance.delete(url, { ...this.createAuthRequestConfig(options), params });
  };

  createAuthRequestConfig = (options) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    };

    const slug = LocalStorage.getItem('slug');

    if (slug) {
      headers['company_slug'] = slug;
    }

    if (!options?.Authorization && LocalStorage.getItem('partner_jwt')) {
      headers['Authorization'] = 'Bearer ' + LocalStorage.getItem('partner_jwt');
    }

    if (options) {
      const { Authorization } = options;
      if (Authorization) {
        headers['Authorization'] = Authorization;
      }
      if (options['Content-Type']) {
        headers['Content-Type'] = options['Content-Type'];
      }
      if (options['slug']) {
        headers['company_slug'] = options['slug'];
      }
    }

    return { headers, cancelToken: options?.axiosCancelToken, responseType: options?.responseType };
  };
}

export const dispatcherAuthService = new DispatcherAuthService(axiosInstance);
