import { get, set } from 'lodash-es/object';

import { FEATURES_MAP, isFeatureEnabled } from './SettingResolver';

export const ADMIN_ACCESSES = {
  TASK_GROUPS: 'task_groups',
  TASK_GROUPS_UNASSIGNED: 'task_groups.unassigned',
  TASK_GROUPS_ASSIGNED: 'task_groups.assigned',
  TASK_GROUPS_BROADCAST: 'task_groups.broadcast',

  INVOICES_SHOW: 'invoices.show',

  ORDERS: 'orders',
  ORDERS_INDEX: 'orders.index',
  ORDERS_CREATE_BATCH: 'batches',
  ORDERS_CREATE: 'orders.create',
  ORDERS_UPDATE: 'orders.update',
  ORDERS_PRICE_SHOW: 'orders.show.price',
  ORDERS_PRICE_UPDATE: 'orders.update.price',
  ORDERS_EXTERNAL_ID_SHOW: 'orders.show.external_id',
  ORDERS_NUMBER_SHOW: 'orders.show.number',
  ORDERS_SENDER_SHOW: 'orders.show.sender',
  ORDERS_EXTERNAL_ID_UPDATE: 'orders.update.external_id',
  ORDERS_LOAD_QUANTITY_SHOW: 'orders.show.load_quantity',
  ORDERS_CONTAINER_NUMBER_SHOW: 'orders.show.container_no',
  ORDERS_CONTAINER_NUMBER_UPDATE: 'orders.update.container_no',

  ORDER_ITEMS: 'order_items',
  ORDER_ITEMS_INDEX: 'order_items.index',

  ITEMS_SERVICE_TYPE_SHOW: 'items.show.service_type',
  ITEMS_EXTERNAL_CUSTOMER_ID_SHOW: 'items.show.external_customer_id',
  ITEMS_EXTERNAL_CUSTOMER_ID2_SHOW: 'items.show.external_customer_id2',
  ITEMS_EXTERNAL_CUSTOMER_ID3_SHOW: 'items.show.external_customer_id3',
  ITEMS_EXTERNAL_CUSTOMER_ID_UPDATE: 'items.update.external_customer_id',
  ITEMS_EXTERNAL_CUSTOMER_ID2_UPDATE: 'items.update.external_customer_id2',
  ITEMS_EXTERNAL_CUSTOMER_ID3_UPDATE: 'items.update.external_customer_id3',
  ITEMS_VEHICLE_TYPE_SHOW: 'items.show.vehicle_type',
  ITEMS_INFO_SHOW: 'items.show.info',
  ITEMS_SHOW_QUANTITY: 'items.show.quantity',
  ITEMS_SHOW_SCANNED_QUANTITY: 'items.show.scanned_quantity',
  ITEMS_SHOW_VEHICLE: 'items.show.vehicle',
  ITEMS_SHOW_GLOBAL_TRACKING_NUMBER: 'items.show.global_tracking_number',
  ITEMS_SHOW_TRACKING_NUMBER: 'items.show.tracking_number',
  ITEMS_SHOW_SHIPPER: 'items.show.shipper_name',

  STEP_ITEM_VOLUME_SHOW: 'step_items.show.volume',

  ORGANISATIONS_INDEX: 'organisations.index',
  ORGANISATIONS_UPDATE: 'organisations.update',
  ORGANISATIONS_DELETE: 'organisations.delete',

  DISPATCHERS_DELETE: 'dispatchers.delete',

  WORKERS: 'workers',
  WORKERS_INDEX: 'workers.index',
  WORKERS_SHOW: 'workers.show',
  WORKERS_CREATE: 'workers.create',
  WORKERS_UPDATE: 'workers.update',
  WORKERS_DELETE: 'workers.delete',

  PARTNERS: 'partners',
  PARTNERS_CREATE: 'partners.create',
  PARTNERS_TRANSFER: 'partners.transfer',

  DASHBOARD: 'dashboard',
  SETTINGS: 'settings',
  NETWORK: 'network',

  REPORTS: 'reports',
  REPORTS_SHOW_ONGOING_AND_COMPLETED: 'reports.show.driver_ongoing_and_completed',
  REPORTS_SHOW_REPORTED: 'reports.show.driver_reported_dropoffs',

  USER_GUIDE_SHOW: 'user_guide',

  SETTINGS_SHOW_CREATE_NEW_USER: 'settings.show.create_new_user',
  SETTINGS_SHOW_DRIVER_MANAGEMENT: 'settings.show.driver_management',

  HEADER_SHOW_HUB_FILTER: 'header.show.hub_filter',

  TASK_ETA_SHOW: 'task.show.eta',

  COMPLETED_QUANTITY_SHOW: 'completed_quantity.show',
};

export const ADMIN_COPY = {
  ORDERS_CCU_TITLE: 'orders.show.ccu_name_title',
  ORDERS_EXTERNAL_ID_TITLE: 'orders.show.external_id_title',
  ORDERS_CUSTOM_FIELD_1_TITLE: 'orders.show.custom_field_1_title',
  ORDERS_CUSTOM_FIELD_2_TITLE: 'orders.show.custom_field_2_title',
  ORDERS_CUSTOM_FIELD_3_TITLE: 'orders.show.custom_field_3_title',
  ORDERS_CUSTOM_FIELD_4_TITLE: 'orders.show.custom_field_4_title',
  ORDER_ID_TITLE: 'items.show.order_id_title',
  ITEMS_EXTERNAL_CUSTOMER_ID_TITLE: 'items.show.external_customer_id_title',
  ITEMS_EXTERNAL_CUSTOMER_ID2_TITLE: 'items.show.external_customer_id2_title',
  ITEMS_EXTERNAL_CUSTOMER_ID3_TITLE: 'items.show.external_customer_id3_title',
  ITEMS_INFO_TITLE: 'items.show.info_title',
  DEFAULT_DIMENSION_UNIT: 'default.unit',
  DEFAULT_VOLUME_UNIT: 'default.volume_unit',
  DEFAULT_WEIGHT_UNIT: 'default.weight_unit',
  AUDIT_LOG_EVENTS: 'auditlog.event_types',
};

export const getValue = (object, path, defaultValue) => {
  return get(object, path, defaultValue);
};

export const setValue = (object, path, value) => {
  return set(object, path, value);
};

export const extractTaskSetting = (companySetting, features = {}) => {
  if (!companySetting || !companySetting.display_settings) return {};
  const adminAccess = companySetting.display_settings.admin_access;
  const adminCopy = companySetting.display_settings.admin_copy;
  const isShowOrderExternalId = getValue(adminAccess, ADMIN_ACCESSES.ORDERS_EXTERNAL_ID_SHOW, false);
  const isShowOrderNumber = getValue(adminAccess, ADMIN_ACCESSES.ORDERS_NUMBER_SHOW, false);
  const isShowSender = getValue(adminAccess, ADMIN_ACCESSES.ORDERS_SENDER_SHOW, false);
  const isShowServiceType = getValue(adminAccess, ADMIN_ACCESSES.ITEMS_SERVICE_TYPE_SHOW, false);
  const isShowItemExternalCustomerId = getValue(adminAccess, ADMIN_ACCESSES.ITEMS_EXTERNAL_CUSTOMER_ID_SHOW, false);
  const isShowItemExternalCustomerId2 = getValue(adminAccess, ADMIN_ACCESSES.ITEMS_EXTERNAL_CUSTOMER_ID2_SHOW, false);
  const isShowItemExternalCustomerId3 = getValue(adminAccess, ADMIN_ACCESSES.ITEMS_EXTERNAL_CUSTOMER_ID3_SHOW, false);
  const isShowContainerNo = getValue(adminAccess, ADMIN_ACCESSES.ORDERS_CONTAINER_NUMBER_SHOW, false);
  const isShowTaskEta = getValue(adminAccess, ADMIN_ACCESSES.TASK_ETA_SHOW, false);
  const isShowItemContainerNo = isFeatureEnabled(features, FEATURES_MAP.USING_CONTAINER);
  const isShowCompletedQuantity = getValue(adminAccess, ADMIN_ACCESSES.COMPLETED_QUANTITY_SHOW, false);
  const isShowGlobalItemTrackingNumber = getValue(adminAccess, ADMIN_ACCESSES.ITEMS_SHOW_GLOBAL_TRACKING_NUMBER, true);
  const isShowItemTrackingNumber = getValue(adminAccess, ADMIN_ACCESSES.ITEMS_SHOW_TRACKING_NUMBER, true);
  const isShowItemShipper = getValue(adminAccess, ADMIN_ACCESSES.ITEMS_SHOW_SHIPPER, true);

  const itemOrderIdTitle = getValue(adminCopy, ADMIN_COPY.ORDER_ID_TITLE, undefined);
  const orderExternalIdTitle = getValue(adminCopy, ADMIN_COPY.ORDERS_EXTERNAL_ID_TITLE);
  const itemExternalCustomerIdTitle = getValue(adminCopy, ADMIN_COPY.ITEMS_EXTERNAL_CUSTOMER_ID_TITLE);
  const itemExternalCustomerId2Title = getValue(adminCopy, ADMIN_COPY.ITEMS_EXTERNAL_CUSTOMER_ID2_TITLE);
  const itemExternalCustomerId3Title = getValue(adminCopy, ADMIN_COPY.ITEMS_EXTERNAL_CUSTOMER_ID3_TITLE);

  return {
    isShowOrderExternalId,
    isShowOrderNumber,
    isShowSender,
    isShowServiceType,
    isShowItemExternalCustomerId,
    isShowItemExternalCustomerId2,
    isShowItemExternalCustomerId3,
    isShowContainerNo,
    isShowItemContainerNo,
    itemOrderIdTitle,
    orderExternalIdTitle,
    itemExternalCustomerIdTitle,
    itemExternalCustomerId2Title,
    itemExternalCustomerId3Title,
    isShowTaskEta,
    isShowCompletedQuantity,
    isShowGlobalItemTrackingNumber,
    isShowItemTrackingNumber,
    isShowItemShipper,
  };
};
