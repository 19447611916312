export const STEP_PROPERTY_TYPES = {
  empty: 'empty',
  origin: 'origin',
  destination: 'destination',
};

export const TASK_GROUP_STATES = {
  unassigned: 'unassigned',
  assigned: 'assigned',
  completed: 'completed',
  invalidated: 'invalidated',
};

export const TASK_STATES = {
  created: 'created',
  completed: 'completed',
  failed: 'failed',
  invalidated: 'invalidated',
};
