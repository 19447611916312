import { call, put, takeLatest } from 'redux-saga/effects';

import { featureReleaseApi } from '@yojee/api/v4/featureReleaseApi';
import { getSlugFromUrl } from '@yojee/auth/utils';

export default function* featureManagementSagas() {
  yield takeLatest('FETCH_RELEASE_TOGGLES', fetchReleaseToggles);
}

function* fetchReleaseToggles() {
  try {
    const { data } = yield call(featureReleaseApi.get, getSlugFromUrl());
    yield put({ type: 'FEATURE_TOGGLES_FETCHED', data });
  } catch (e) {
    console.error(e);
  }
}
