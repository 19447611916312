import { all, fork, select, takeEvery } from '@redux-saga/core/effects';

import featureManagementSagas from '@yojee/ui/feature-management/saga/saga';
import { MapSaga } from '@yojee/ui/map';

import company from './company/companySaga';
import order from './order/orderSaga';

function* watchAndLog() {
  if (process.env.REACT_APP_IS_LOCAL === 'true' && process.env.REACT_OUTPUT_STATE_CHANGES) {
    yield takeEvery('*', function* logger(action) {
      const state = yield select();
      console.log('action', action);
      console.log('state after', state);
    });
  }
}

export default function* root() {
  yield all([fork(watchAndLog), fork(order), fork(company), fork(MapSaga), fork(featureManagementSagas)]);
}
