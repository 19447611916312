import getEnv from '@yojee/helpers/env/getEnv';
import { fromErrorsToMessages } from '@yojee/helpers/errorHelper';

const API_URL = getEnv('REACT_APP_API_URL');
const API_URL_V4 = getEnv('REACT_APP_API_URL_V4');
const GEODIS_API_URL = getEnv('REACT_APP_GEODIS_API');
const SOLVER_URL = getEnv('REACT_APP_SOLVER_URL');
const ENV = process.env.REACT_APP_ENV;

export const handleError = (error) => {
  const { response } = error;
  if (!response) throw error;
  const errorObj = {
    statusCode: response.status ? response.status : null,
    message: null,
    statusText: response.statusText ? response.statusText : null,
  };
  if (response['data']) {
    const { data } = response;
    if (data['failed_operation'] && data['failed_value']) {
      errorObj.message = data['failed_value'];
    } else if (data['message']) {
      errorObj.message = data['message'];
    } else if (data['data']) {
      errorObj.message = !Array.isArray(data['data'])
        ? Object.keys(data['data']).map((key) => data['data'][key][0])[0]
        : data['data'];
    }
  }

  if (!errorObj.message) {
    errorObj.message = error.toString();
  }

  if (response?.data) {
    const { data = [] } = response;
    errorObj.data = data?.data;
  }

  throw errorObj;
};

export const handleNewErrorFormat = (error) => {
  const { response } = error;

  if (!response) throw error;
  let errorObj = {
    statusCode: response.status ? response.status : null,
    message: null,
    errorMessages: [],
    errors: [],
    statusText: response.statusText ? response.statusText : null,
  };

  if (response?.data?.errors) {
    const errors = response.data.errors;
    const errorMessages = fromErrorsToMessages(errors);

    errorObj = {
      ...errorObj,
      message: errorMessages.join('\n'),
      errorMessages,
      errors,
    };
  }

  if (!errorObj.message) {
    errorObj.message = error.toString();
  }

  throw errorObj;
};

export class BaseService {
  apiUrl = null;

  static getUmbrellaApiHostUrl() {
    return `${API_URL}`;
  }

  static getUmbrellaApiHostUrlV4() {
    return `${API_URL_V4}`;
  }

  getUmbrellaApiUrl(path) {
    return `${API_URL}/${path}`;
  }

  getUmbrellaApiUrlV4(path) {
    return `${API_URL_V4}/${path}`;
  }

  getGeodisApiUrl = (path) => {
    return `${GEODIS_API_URL || this.apiUrl}/${path}`;
  };

  _getSolverApiUrl = (path) => {
    return `${SOLVER_URL}/${path}`;
  };

  _getEnv() {
    return ENV;
  }

  extractData(response) {
    return response && response['data'] ? response['data'] : response;
  }

  handleError = (error) => {
    handleError(error);
  };

  handleNewErrorFormat = (error) => {
    handleNewErrorFormat(error);
  };
}

export const baseService = new BaseService();
