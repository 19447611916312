const PREFIX = 'swx';
const ENV = process.env.REACT_APP_ENV;

const getKey = (key) => {
  return PREFIX + '-' + key + '_' + ENV;
};

export class LocalStorage {
  static getItem = (key) => {
    const item = localStorage.getItem(getKey(key));
    if (!item) {
      return undefined;
    }
    try {
      const { data } = JSON.parse(item);
      return data;
    } catch (e) {
      return undefined;
    }
  };

  static removeItem = (key) => {
    localStorage.removeItem(getKey(key));
    sessionStorage.removeItem(getKey(key));
  };

  static setItem = (key, data) => {
    localStorage.setItem(getKey(key), JSON.stringify({ data: data }));
    sessionStorage.setItem(getKey(key), JSON.stringify({ data: data }));
  };

  static hasItem = (key) => {
    return LocalStorage.getItem(key) !== undefined;
  };
}

export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(getKey(key));
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(getKey(key), serializedState);
  } catch {
    // ignore write errors
  }
};
