export const getItemDetails = (payload) => {
  return { type: 'REQUEST_GET_ITEM_DETAILS', payload };
};

export const getOrderDetails = (payload) => {
  return { type: 'REQUEST_GET_ORDER_DETAILS', payload };
};

export const resetErrors = () => {
  return { type: 'RESET_ERRORS' };
};
