const defaultState = {
  companyFeatures: {
    task_assignment_with_vehicle: false,
    worker_sequence_rearrangement: false,
  },
  featureToggles: {},
};

export const init = () => defaultState;

const ACTION_HANDLERS = {
  RESET_ALL_STORE: init,
  // For apps which use auth
  UI_AUTH_GET_DISPATCHER_INFO_SUCCESSFUL: (state, { dispatcher_info }) => ({
    ...state,
    companyFeatures: {
      ...state.companyFeatures,
      ...dispatcher_info.data.company.settings.features,
    },
  }),
  UI_AUTH_SET_DISPATCHER_INFO: (state, { payload }) => ({
    ...state,
    companyFeatures: {
      ...state.companyFeatures,
      ...payload.company.settings.features,
    },
  }),
  UI_AUTH_UPDATE_COMPANY_SETTINGS: (state, { companyInfo }) => ({
    ...state,
    companyFeatures: {
      ...state.companyFeatures,
      ...companyInfo.data.settings.features,
    },
  }),
  GET_COMPANY_INFO_SUCCESSFUL: (state, { companyData }) => ({
    ...state,
    companyFeatures: {
      ...state.companyFeatures,
      ...companyData.features,
    },
  }),
  FEATURE_TOGGLES_FETCHED: (state, { data }) => ({
    ...state,
    featureToggles: data,
  }),
};

export const featuresReducer = (state = defaultState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
