import { Trans } from 'react-i18next';

import { ITEM_STATUSES } from '../../infrastructure';

const ITEM_STATUS_NUMBER_MAP = {
  [ITEM_STATUSES.cancelled]: -1,
  [ITEM_STATUSES.processing]: 1,
  [ITEM_STATUSES.outForPickup]: 2,
  [ITEM_STATUSES.inTransit]: 3,
  [ITEM_STATUSES.outForDelivery]: 4,
  [ITEM_STATUSES.delivered]: 5,
};

const ItemDeliveryProgressBar = ({ itemStatus }) => {
  const itemStatusNo = ITEM_STATUS_NUMBER_MAP[itemStatus];

  const brandingStyle = null;

  return (
    <div className="item-progress-container" data-cy="tracking-item-progress">
      <div className="item-progress-status-container" data-cy="tracking-item-progress-status">
        <div
          className={
            'status-processing step ' + (itemStatusNo > 0 ? 'active' : '') + (itemStatusNo === 1 ? ' last' : '')
          }
          style={brandingStyle ? brandingStyle['admin-primary-color'] : null}
        >
          {itemStatusNo < 0 ? <Trans>Cancelled</Trans> : <Trans>Processing</Trans>}
        </div>
        <div
          className={
            'status-out-for-pickup step ' + (itemStatusNo > 1 ? 'active' : '') + (itemStatusNo === 2 ? ' last' : '')
          }
          style={brandingStyle ? brandingStyle['admin-primary-color'] : null}
        >
          <Trans>Out for Pick up</Trans>
        </div>
        <div
          className={
            'status-in-transit step ' + (itemStatusNo > 2 ? 'active' : '') + (itemStatusNo === 3 ? ' last' : '')
          }
          style={brandingStyle ? brandingStyle['admin-primary-color'] : null}
        >
          <Trans>In Transit</Trans>
        </div>
        <div
          className={
            'status-out-for-delivery step ' + (itemStatusNo > 3 ? 'active' : '') + (itemStatusNo === 4 ? ' last' : '')
          }
          style={brandingStyle ? brandingStyle['admin-primary-color'] : null}
        >
          <Trans>Out for Delivery</Trans>
        </div>
        <div
          className={'status-delivered step ' + (itemStatusNo > 4 ? 'active' : '')}
          style={brandingStyle ? brandingStyle['admin-primary-color'] : null}
        >
          <Trans>Delivered</Trans>
        </div>
      </div>
      <div className="item-progress-point-container" data-cy="tracking-item-progress-status-point">
        <div
          className={'step ' + (itemStatusNo > 0 ? 'active' : '') + (itemStatusNo === 1 ? ' last' : '')}
          style={brandingStyle ? brandingStyle['admin-primary-color'] : null}
        />
        <div
          className={'step ' + (itemStatusNo > 1 ? 'active' : '') + (itemStatusNo === 2 ? ' last' : '')}
          style={brandingStyle ? brandingStyle['admin-primary-color'] : null}
        />
        <div
          className={'step ' + (itemStatusNo > 2 ? 'active' : '') + (itemStatusNo === 3 ? ' last' : '')}
          style={brandingStyle ? brandingStyle['admin-primary-color'] : null}
        />
        <div
          className={'step ' + (itemStatusNo > 3 ? 'active' : '') + (itemStatusNo === 4 ? ' last' : '')}
          style={brandingStyle ? brandingStyle['admin-primary-color'] : null}
        />
        <div
          className={'step ' + (itemStatusNo > 4 ? 'active' : '')}
          style={brandingStyle ? brandingStyle['admin-primary-color'] : null}
        />
      </div>
    </div>
  );
};

export default ItemDeliveryProgressBar;
