import { LocalStorage } from '@yojee/local-storage/local-storage';

import { DEFAULT_LANGUAGE } from './constants';

function getBrowserLanguage() {
  const browserLocale = navigator.language || DEFAULT_LANGUAGE;

  return browserLocale.trim().split(/[-_]/)[0];
}

export const isSupportedLanguage = (language: string, supportedLanguages: string[]) => {
  return supportedLanguages.includes(language);
};

export const languageStorage = {
  getSavedLanguage: () => LocalStorage.getItem('system_language'),
  setSavedLanguage: (lang: string) => LocalStorage.setItem('system_language', lang),
};

export function getSystemLanguageForUser(
  userLanguage = languageStorage.getSavedLanguage(),
  supportedLanguages?: string[]
) {
  let language = userLanguage || getBrowserLanguage();

  if (supportedLanguages && !isSupportedLanguage(language, supportedLanguages)) {
    languageStorage.setSavedLanguage(DEFAULT_LANGUAGE);
    language = DEFAULT_LANGUAGE;
  }

  return language;
}
