import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import * as featureManagementActions from '@yojee/ui/feature-management/saga/actions';

import * as companyActions from '../../sagas/company/companyActions';
import { CommonService } from '../../services/commonService';
import Header from './Header';
import Loading from './Loading';

const Layout = ({ className }) => {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company.companyInfo);
  const isLoading = useSelector(
    (state) =>
      state.order.inProgress.getItemDetails ||
      state.order.inProgress.getOrderDetails ||
      state.company.loading.getCompanyInfo
  );

  useEffect(() => {
    if (!company.currency) {
      dispatch(companyActions.getCompanyInfo());
    } else {
      CommonService.setFavicon(company.branding.favicon);
    }

    dispatch(featureManagementActions.fetchReleaseToggles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <div className={'main-container' + (className ? ` ${className}` : '')}>
        <Header />
        <div className="content-container">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
