import { GetReducerState, RouterReducer } from '@yojee/types';
import { featuresReducer } from '@yojee/ui/feature-management/reducers/reducer';
import mapReducer from '@yojee/ui/map/reducers/reducer';

import companyReducer from './company';
import orderReducer from './order';

const reducers = (routerReducer: RouterReducer) => ({
  router: routerReducer,
  company: companyReducer,
  order: orderReducer,
  map: mapReducer,
  features: featuresReducer,
});

type Reducers = ReturnType<typeof reducers>;

export type TrackingState = {
  [k in keyof Reducers]: GetReducerState<Reducers[k]>;
};

export default reducers;
