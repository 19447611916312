import '../../styles/components/item.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import { FEATURES_MAP } from '@yojee/helpers/SettingResolver';
import useIsFeatureEnabled from '@yojee/ui/feature-management/hooks/useIsFeatureEnabled';

import { getItemCurrentStatus, isObjectEmpty, ITEM_STATUSES } from '../../infrastructure';
import { OrderItem } from '../../types';
import ItemDeliveryProgressBar from './ItemDeliveryProgressBar';
import ItemLogsTable from './ItemLogsTable';
import ItemTopInfo from './ItemTopInfo';
import TrackingMap from './TrackingMap';

interface Props {
  item: OrderItem;
  isItemDetailsShown: boolean;
}

const Item = ({ item, isItemDetailsShown = true }: Props) => {
  const [itemStatus, setItemStatus] = useState<ITEM_STATUSES | undefined>(undefined);
  const isLiveTrackingEnabledSetting = useIsFeatureEnabled(FEATURES_MAP.LIVE_TRACKING_MAP);

  useEffect(() => {
    if (item) {
      setItemStatus(getItemCurrentStatus(item));
    }
  }, [item]);

  const isItemTransferred = useMemo(() => {
    return !!item.order_item_steps?.find((step) => step.state === 'transferred');
  }, [item]);

  if (isObjectEmpty(item)) {
    return null;
  }

  const canShowTrackingMap =
    !isItemTransferred && isLiveTrackingEnabledSetting && isItemDetailsShown && itemStatus !== ITEM_STATUSES.delivered;

  return (
    <div className="item-container" data-cy="tracking-item-details">
      {isItemDetailsShown && itemStatus !== undefined && <ItemTopInfo item={item} itemStatus={itemStatus} />}
      {itemStatus !== ITEM_STATUSES.cancelled ? (
        <>
          {canShowTrackingMap && <TrackingMap item={item} />}
          <ItemDeliveryProgressBar itemStatus={itemStatus} />
          {isItemTransferred ? (
            <div className="item-transferred-info">
              <h4>
                <Trans>Your order has been transferred to our partner</Trans>
              </h4>
              <p>
                <Trans>You can follow the status of your order, but live tracking and updates are not available</Trans>
              </p>
            </div>
          ) : (
            isItemDetailsShown && <ItemLogsTable item={item} />
          )}
        </>
      ) : (
        <div className="item-cancelled-container" data-cy="tracking-item-cancelled" />
      )}
    </div>
  );
};

export default Item;
