export const getCompanyInfo = () => {
  return { type: 'REQUEST_GET_COMPANY_INFO' };
};

export const getCompanyServiceTypes = () => {
  return { type: 'REQUEST_GET_COMPANY_SERVICE_TYPES' };
};

export const getJwtToken = (payload) => {
  return { type: 'REQUEST_GET_JWT_TOKEN', payload };
};

export const getTaskTypes = () => {
  return { type: 'REQUEST_GET_TASK_TYPES' };
};
