import { authService } from './authService';
import { BaseService } from './baseService';

export class OrderService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getOrderDetails = (orderTrackingNumber) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`public/track/${orderTrackingNumber}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformPublicTrackingData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  getItemDetails = (itemTrackingNumber) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`public/track_item/${itemTrackingNumber}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformPublicTrackingData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  _transformPublicTrackingData = (data) => {
    const workers = {};
    return {
      ...data,
      order_items: data?.order_items?.map((item) => {
        return {
          ...item,
          tasks: item?.tasks?.map((task) => {
            if (task.task_group.worker_id) {
              workers[task.task_group.worker_id] = {
                id: task.task_group.worker_id,
                name: task.task_group.worker_name,
                location: task.task_group.worker_location,
              };
            }
            return {
              ...task,
              task_group: {
                worker_id: task.task_group.worker_id,
                state: task.task_group.state,
                worker_location_updated_at: task.task_group.worker_location_updated_at,
                accepted_time: task.task_group.accepted_time,
              },
              task: {
                id: task.id,
                eta: task.eta,
                position: task.position,
                state: task.state,
              },
              order_step: {
                address: item.order_item_steps.find((step) => step.step_sequence === task.step_sequence)?.address,
              },
              order_item: {
                id: item.item_id,
                state: item.status,
                tracking_number: item.tracking_number,
              },
            };
          }),
        };
      }),
      workers: Object.values(workers),
    };
  };
}

export const orderService = new OrderService({
  authService,
});
