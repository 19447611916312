import React from 'react';
import { Router } from 'react-router-dom';

export function HistoryRouter(props) {
  const { basename, children, history } = props;
  const [state, setState] = React.useState({
    action: history?.action,
    location: history?.location ?? { pathname: '' },
  });

  React.useLayoutEffect(() => history && history.listen(setState), [history]);

  return React.createElement(Router, {
    basename: basename,
    children: children,
    location: state.location,
    navigationType: state.action,
    navigator: history,
  });
}
