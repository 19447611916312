export const getLanguageFiles = () => {
  const requireFile = require.context('../../../../../i18n/lokalise', false, /\.json$/, 'lazy');
  const langFiles = requireFile.keys();

  return langFiles.reduce((acc, langFile) => {
    const langCode = /(\w+)\.json$/.exec(langFile)?.[1];

    if (langCode) {
      acc[langCode] = () => requireFile(langFile);
    }

    return acc;
  }, {} as Record<string, () => Promise<Record<string, string>>>);
};
