import i18n, { ReadCallback } from 'i18next';
import Backend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE, getSystemLanguageForUser } from '@yojee/helpers/languages';

import getEnv from '../env/getEnv';
import { getLanguageFiles } from './getLanguageFiles';

const isLocal = getEnv('REACT_APP_IS_LOCAL') === 'true';

const backend = {
  backends: [
    resourcesToBackend(async (selectedLang: string, ns: string, callback: ReadCallback) => {
      const fileMaps = getLanguageFiles();
      const getLanguage = fileMaps[selectedLang];

      const getLiveTranslations = getLanguage ? getLanguage() : Promise.resolve({});
      const getLocalTranslations = isLocal
        ? import(`@yojee/i18n/localhost/en.json`).then((res) => res.default)
        : Promise.resolve({});

      const [liveTranslations, localTranslations] = await Promise.all([getLiveTranslations, getLocalTranslations]);

      callback(null, {
        ...localTranslations,
        ...liveTranslations,
      });
    }),
  ],
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    saveMissing: false,
    backend,
    lng: getSystemLanguageForUser(),
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
