// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { selectTaskByMapSelection } from '@yojee/ui/map/actions';

const MapDrawingMode = {
  Rectangle: 'Rectangle',
  None: 'None',
};

export default function DrawingMapController(props) {
  const dispatch = useDispatch();
  const { map, google, selectionMode, taskMarkers } = props;
  const [myDrawingManager, setMyDrawingManager] = useState(undefined);
  const [selectionBound, setSelectionBound] = useState(undefined);
  const [currentRect, setCurrentRect] = useState(undefined);
  const [neBound, setNeBound] = useState(undefined);
  const [swBound, setSwBound] = useState(undefined);
  useEffect(() => {
    if (!selectionMode && currentRect) {
      currentRect.setMap(null);
      setCurrentRect(null);
    }
    if (!selectionMode && myDrawingManager) {
      myDrawingManager.setMap(null);
      setMyDrawingManager(null);
    }
  }, [selectionMode]);
  useEffect(() => {
    if (google && google.maps && selectionMode && !myDrawingManager) {
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['rectangle'],
        },
        markerOptions: {
          icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
        },
        rectangleOptions: {
          fillColor: '#ffff00',
          fillOpacity: 0.2,
          strokeWeight: 2,
          clickable: true,
          draggable: true,
          editable: true,
          zIndex: 1,
        },
      });
      drawingManager.setMap(map);
      setMyDrawingManager(drawingManager);
      google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
        overlayCompleteHandler(event, drawingManager);
      });
    }
  }, [google, selectionMode]);
  useEffect(() => {
    if (selectionMode && selectionBound && taskMarkers && taskMarkers.length > 0) {
      const markerInbounds = taskMarkers.filter((m) => selectionBound.contains({ lat: m.lat, lng: m.lng }));
      const tasksSelected =
        markerInbounds && markerInbounds.length > 0
          ? markerInbounds.reduce((previous, current) => {
              const stops = current.data && current.data.stops;
              if (stops && stops.length > 0) {
                return previous.concat(
                  stops.reduce((previousTasks, currentStop) => {
                    return previousTasks.concat(currentStop.tasks);
                  }, [])
                );
              }
              return previous;
            }, [])
          : [];
      dispatch(selectTaskByMapSelection(tasksSelected));
    }
  }, [neBound, swBound]);

  const overlayCompleteHandler = (event, drawingManager) => {
    if (event.type === 'rectangle') {
      resetDrawingMode(drawingManager, MapDrawingMode.None);
      const newShape = event.overlay;
      newShape.type = event.type;
      extractBound(newShape);
      newShape.addListener('bounds_changed', () => {
        extractBound(newShape);
      });
      setCurrentRect(newShape);
      google.maps.event.addListener(newShape, 'click', () => {
        newShape.setMap(null);
        resetDrawingMode(drawingManager, MapDrawingMode.Rectangle);
      });
    }
  };
  const extractBound = (newShape) => {
    const ne = newShape.getBounds().getNorthEast();
    const sw = newShape.getBounds().getSouthWest();
    setSelectionBound(newShape.getBounds());
    setNeBound(ne.toJSON());
    setSwBound(sw.toJSON());
  };

  const resetDrawingMode = (drawingManager, mode) => {
    switch (mode) {
      case MapDrawingMode.Rectangle: {
        drawingManager.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE);
        drawingManager.setOptions({
          drawingControl: true,
        });
        return;
      }
      default: {
        drawingManager.setDrawingMode(null);
        drawingManager.setOptions({
          drawingControl: false,
        });
      }
    }
  };
  return null;
}
