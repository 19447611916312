import jwtDecode from 'jwt-decode';

import { LocalStorage } from '@yojee/local-storage/local-storage';

export const decodeJwtData = (jwtData) => {
  if (!jwtData) return;
  const accessToken = jwtData['access_token'] || null;
  const refreshToken = jwtData['refresh_token'] || null;
  const decodedJwt = {
    access_token: accessToken,
    refresh_token: refreshToken,
    access_token_expiry: null,
    refresh_token_expiry: null,
    permissions_updated_at: null,
  };
  if (accessToken) {
    const { exp, permissions_updated_at } = jwtDecode(accessToken);
    decodedJwt['permissions_updated_at'] = permissions_updated_at;
    decodedJwt['access_token_expiry'] = exp;
  }

  if (refreshToken) {
    const { exp } = jwtDecode(refreshToken);
    decodedJwt['refresh_token_expiry'] = exp;
  }
  return decodedJwt;
};

export const saveJwtData = (decodedJwtData) => {
  if (!decodedJwtData) return;
  LocalStorage.setItem('access_token', decodedJwtData['access_token']);
  LocalStorage.setItem('access_token_expiry', decodedJwtData['access_token_expiry']);
  LocalStorage.setItem('permissions_updated_at', decodedJwtData['permissions_updated_at']);
  LocalStorage.setItem('refresh_token', decodedJwtData['refresh_token']);
  LocalStorage.setItem('refresh_token_expiry', decodedJwtData['refresh_token_expiry']);
};

export const clearJwtData = () => {
  LocalStorage.removeItem('access_token');
  LocalStorage.removeItem('access_token_expiry');
  LocalStorage.removeItem('permissions_updated_at');
  LocalStorage.removeItem('refresh_token');
  LocalStorage.removeItem('refresh_token_expiry');
};

export const getJwtData = () => {
  if (LocalStorage.hasItem('access_token') && LocalStorage.hasItem('access_token_expiry')) {
    return {
      access_token: LocalStorage.getItem('access_token'),
      refresh_token: LocalStorage.getItem('refresh_token'),
      access_token_expiry: LocalStorage.getItem('access_token_expiry'),
      refresh_token_expiry: LocalStorage.getItem('refresh_token_expiry'),
      permissions_updated_at: LocalStorage.getItem('permissions_updated_at'),
    };
  }
  return null;
};
