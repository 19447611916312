import { Socket } from 'phoenix';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SOCKET_EVENTS } from '@yojee/helpers/constants';
import getEnv from '@yojee/helpers/env/getEnv';
import { getMapKeyName } from '@yojee/helpers/map-helper';
import { tasksToStops } from '@yojee/helpers/tasksToStops';
import { uuidv4 } from '@yojee/helpers/uuidv4';
import { DrawingMapController, GoogleMap, MapController, MarkersCanvas } from '@yojee/ui/map';
import * as mapActions from '@yojee/ui/map/actions';

const PUBLIC_SOCKET_URL = getEnv('REACT_APP_PUBLIC_SOCKET_URL');

const TrackingMap = ({ item: { tasks: _tasks, tracking_number: trackingNumber, item_id: itemId } }) => {
  const dispatch = useDispatch();
  const [google, setGoogle] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [map, setMap] = useState(null);
  const [isWorkerMarkerSet, setIsWorkerMarkerSet] = useState(false);

  const workers = useSelector((state) => state.order.orderData.workers);
  const taskTypes = useSelector((state) => state.company?.taskTypes ?? []);
  const [zoomedToOrderLocationFisrtTimeAlready, setZoomedToOrderLocationFisrtTimeAlready] = useState(false);

  useEffect(() => {
    setTasks(
      _tasks
        .filter((task) => task.task.state !== 'failed')
        .sort((a, b) => {
          if (a['step_sequence'] && b['step_sequence']) {
            return a['step_sequence'] - b['step_sequence'];
          }
          return 0;
        })
    );

    initPublicTrackingSocket();
  }, []);

  useEffect(() => {
    if (workers?.length && tasks?.length && !isWorkerMarkerSet && google) {
      setIsWorkerMarkerSet(true);
      initiateDriverMarkersOnMap(google);
    }
  }, [workers, tasks, google]);

  useEffect(() => {
    zoomSpecificToTaskLocations();
  }, [workers, tasks]);

  const onMapChange = (mapChangeProps) => {
    dispatch(mapActions.calculateZoom({ data: mapChangeProps, mapKey: getMapKeyName(itemId, 'tracking') }));

    if (!zoomedToOrderLocationFisrtTimeAlready) {
      zoomSpecificToTaskLocations();

      setZoomedToOrderLocationFisrtTimeAlready(true);
    }
  };

  const zoomSpecificToTaskLocations = () => {
    if (workers?.length || tasks?.length) {
      const _nextTask = tasks.find((task) => task.task.state === 'created');
      dispatch(
        mapActions.zoomSpecific({
          data: [
            ...tasks,
            ...(_nextTask
              ? workers
                  .filter((w) => _nextTask.task_group.worker_id === w.id && w?.location?.lat)
                  .map((worker) => ({ location: worker.location }))
              : []),
          ],
          mapKey: getMapKeyName(itemId, 'tracking'),
        })
      );
    }
  };

  const handleGoogleMapApi = (googleObj) => {
    setMap(googleObj.map);
    setGoogle(googleObj);
  };

  const initiateDriverMarkersOnMap = (googleObj) => {
    if (!showDriverMarker || !workers?.length || !googleObj) {
      return;
    }
    const _nextTask = tasks.find((task) => task.task.state === 'created');
    const _workersWithValidLocation = workers.filter(
      (w) => w.id === _nextTask?.task_group.worker_id && w?.location?.lat
    );
    MapController.addMarkers(_workersWithValidLocation, googleObj.maps, googleObj.map, () => {});
  };

  const createTaskMarker = () => {
    const markers = [];
    const stops = tasksToStops(tasks.filter((task) => task?.task?.state !== 'failed'));
    stops
      .filter((t) => t.location && t.location.lat && t.location.lng)
      .forEach((stop) => {
        const newMarker = {
          id: stop.id,
          lat: stop.location.lat,
          lng: stop.location.lng,
          data: {
            stop,
          },
        };
        markers.push(newMarker);
      });

    let result = [];
    markers.forEach((m) => {
      if (result && result.length > 0) {
        const sameLocation = result.find((r) => r.lat === m.lat && r.lng === m.lng);
        if (!sameLocation) {
          result = result.concat({
            id: uuidv4(),
            lat: m.lat,
            lng: m.lng,
            data: {
              stops: [m.data.stop],
            },
          });
        } else {
          sameLocation.data.stops = sameLocation.data.stops.concat(m.data.stop);
        }
      } else {
        result = result.concat({
          id: uuidv4(),
          lat: m.lat,
          lng: m.lng,
          data: {
            stops: [m.data.stop],
          },
        });
      }
    });
    return result;
  };

  const currentTaskMarkers = useMemo(() => {
    return createTaskMarker();
  }, [tasks]);

  const showDriverMarker = useMemo(() => {
    const _tasks = tasks
      .filter((task) => task.task.state !== 'failed')
      .sort((a, b) => {
        if (a['step_sequence'] && b['step_sequence']) {
          return a['step_sequence'] - b['step_sequence'];
        }
        return 0;
      });

    if (_tasks?.length > 0) {
      // in processing
      if (_tasks[0].task.state === 'created') {
        return true;
      }
      // out of delivery
      if (_tasks[_tasks.length - 2].task.state === 'completed' && _tasks[_tasks.length - 1].task.state === 'created') {
        return true;
      }
    }

    return false;
  }, [tasks]);

  const initPublicTrackingSocket = () => {
    if (trackingNumber) {
      const socket = new Socket(PUBLIC_SOCKET_URL, {});
      socket.connect();
      const channel = socket.channel(`public_item_tracker:${trackingNumber}`);

      channel
        .join()
        .receive('error', ({ reason }) => {
          if (reason === 'Item has not yet been processed') {
            console.log('failed join because item has not yet been processed');
          } else {
            console.error('failed join', reason);
          }
        })
        .receive('timeout', () => {
          console.error('Networking issue. Still waiting...');
        });

      channel.on('notification', (msg) => {
        if (msg) {
          const { event_type, payload } = msg;
          if (event_type === SOCKET_EVENTS.LOCATION_UPDATE && payload?.worker) {
            const _worker = workers.find((worker) => worker.id === payload.worker.id);
            if (_worker) {
              MapController.driverMove({
                id: payload.worker.id,
                location: payload.worker.location,
              });
            }
          } else if (event_type === SOCKET_EVENTS.DROP_OFF_COMPLETED && msg.order_item_state === 'completed') {
            window.location.reload();
          }
        }
      });
    }
  };

  return (
    <div className="map-container" data-cy="tracking-item-details-map">
      <GoogleMap
        onMapChange={onMapChange}
        mapKey={getMapKeyName(itemId, 'tracking')}
        zoomControl={true}
        handleGoogleMapApi={handleGoogleMapApi}
      />
      <DrawingMapController map={map} google={google} selectionMode={false} taskMarkers={currentTaskMarkers} />
      {currentTaskMarkers && (
        <MarkersCanvas map={map} google={google} markers={currentTaskMarkers} workers={workers} taskTypes={taskTypes} />
      )}
    </div>
  );
};

export default TrackingMap;
