import { authService } from '../authService/index';
import { BaseService } from '../baseService/index';

export class FeatureReleaseApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  get = (slug) =>
    this.authService
      .get(this.getUmbrellaApiUrlV4(`public/release_toggles`), { slug })
      .then(this.extractData)
      .catch(this.handleNewErrorFormat);
}

export const featureReleaseApi = new FeatureReleaseApi({ authService });
