import '../styles/components/orderTracking.scss';

import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useFeatureTracker from '@yojee/ui/event-tracking/hooks/useFeatureTracker';
import { MapController } from '@yojee/ui/map';

import { getTaskTypes } from '../sagas/company/companyActions';
import * as orderActions from '../sagas/order/orderActions';
import Item from './Item/index';

const ItemTracking = () => {
  useFeatureTracker('item_tracking', true);
  const dispatch = useDispatch();

  const orderData = useSelector((state) => state.order?.orderData);
  const failed = useSelector((state) => state.order?.error?.failed);
  const companySettings = useSelector((state) => state.company?.companyInfo?.copy);

  const { itemTrackingNumber } = useParams();
  useEffect(() => {
    if (itemTrackingNumber) {
      dispatch(orderActions.getItemDetails(itemTrackingNumber));
      dispatch(getTaskTypes());
      MapController.monitorDriverMovement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTrackingNumber]);

  const itemDetails = orderData?.order_items?.length > 0 ? orderData.order_items[0] : null;

  return (
    <div>
      {failed ? (
        <div className="invalid-tracking-number-container" data-cy="tracking-invalid">
          <h2>
            <Trans>Invalid Tracking Number</Trans>
          </h2>
          <div className="text">
            <Trans>Please check your tracking number and try again</Trans>.
          </div>
        </div>
      ) : itemDetails?.item_id ? (
        <div className="item-details-container">
          <Item
            key={`item-form-${itemDetails.item_id}`}
            index="0"
            item={itemDetails}
            isItemTrackingNumberShown={false}
            isItemLogShown={true}
            companySettings={companySettings}
            isItemDetailsShown={true}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ItemTracking;
