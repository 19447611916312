import { t } from 'i18next';
import moment from 'moment';

import { FORMAT_DATE_TIME_FOR_LOGS } from './constants';

export const DATE_FORMAT = 'DD MMM YYYY';
export const DATE_TIME_FORMAT = 'DD MMM YYYY HH:mm';
export const DATE_TIME_SECOND_FORMAT = 'DD MMM YYYY HH:mm:ss';
export const DATE_TIME_FORMAT_DDMMMYYYY_DOT_HHMM = 'DD MMM YYYY • HH:mm';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_PICKER_FORMAT = 'dd/MM/yyyy';

export const getNormalisedTime = (time, defaultHour, defaultMinute, defaultSecond, isClosingTime = false) => {
  if (time) {
    time = time.trim();
    const colonCount = (time.match(/:/g) || []).length;

    if (colonCount === 0) {
      if (isClosingTime) {
        return `${time}:00:00`;
      }
      return `${time}:${defaultMinute}:${defaultSecond}`;
    } else if (colonCount === 1) {
      const parts = time.split(':');
      return `${parts[0]}:${parts[1]}:00`;
    }
  }

  return `${defaultHour}:${defaultMinute}:${defaultSecond}`;
};

export const getTaskTimeWindow = (taskFrom, taskTo, hubFrom, hubTo) => {
  const taskFromMoment = moment(taskFrom);
  const taskToMoment = moment(taskTo);
  const hubFromTime = moment(hubFrom, 'HH:mm:ss');
  const hubToTime = moment(hubTo, 'HH:mm:ss');

  const hubFromMoment = moment(taskFrom).set({
    hour: hubFromTime.get('hour'),
    minute: hubFromTime.get('minute'),
    second: hubFromTime.get('second'),
  });
  const hubToMoment = moment(taskFrom).set({
    hour: hubToTime.get('hour'),
    minute: hubToTime.get('minute'),
    second: hubToTime.get('second'),
  });

  if (hubFromMoment.isAfter(taskToMoment)) {
    return null;
  }

  const start = hubFromMoment.isAfter(taskFromMoment) ? hubFromMoment : taskFromMoment;
  const end = taskToMoment.isAfter(hubToMoment) ? hubToMoment : taskToMoment;

  return { start, end };
};

export const getFormattedTimeWindow = ({ start, end }) => {
  const fromDate = start ? moment(start).format('DD MMM YY') : '_';
  const toDate = end ? moment(end).format('DD MMM YY') : '_';
  const fromTime = start ? moment(start).format('HH:mm') : '_';
  const toTime = end ? moment(end).format('HH:mm') : '_';
  const isSameDate = fromDate === toDate;
  return isSameDate ? `${toDate} ${fromTime} - ${toTime}` : `${fromDate} ${fromTime} -  ${toDate} ${toTime}`;
};

export function parseISO8601Date(target) {
  if (!target) {
    return null;
  }

  const date = new Date();
  const match = target
    .toString()
    .match(
      new RegExp(/(\d\d\d\d)(-)?(\d\d)(-)?(\d\d)(T)?(\d\d)(:)?(\d\d)(:)?(\d\d)(\.\d+)?(Z|([+-])(\d\d)(:)?(\d\d))/)
    );

  if (match) {
    date.setUTCDate(1);
    date.setUTCFullYear(parseInt(match[1], 10));
    date.setUTCMonth(parseInt(match[3], 10) - 1);
    date.setUTCDate(parseInt(match[5], 10));
    date.setUTCHours(parseInt(match[7], 10));
    date.setUTCMinutes(parseInt(match[9], 10));
    date.setUTCSeconds(parseInt(match[11], 10));

    if (match[12]) {
      date.setUTCMilliseconds(parseFloat(match[12]) * 1000);
    } else {
      date.setUTCMilliseconds(0);
    }

    if (match[13] !== 'Z') {
      let offset = match[15] * 60 + parseInt(match[17], 10);
      offset *= match[14] === '-' ? -1 : 1;
      date.setTime(date.getTime() - offset * 60 * 1000);
    }
  } else {
    date.setTime(Date.parse(target));
  }

  return date;
}

export const utcLocalFormat = (date) => moment.utc(date).local().format(DATE_TIME_FORMAT_DDMMMYYYY_DOT_HHMM);

// https://momentjs.com/docs/#/customization/relative-time/
// https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/02-fromnow/
// No need to set for `ss` token because it's unset by moment anyway!
export const updateMomentLocaleForRelativeTime = () => {
  moment.updateLocale('en', {
    relativeTime: {
      future: '%s',
      past: '%s',
      s: () => {
        return t('secondsAgo');
      },
      m: (number, withoutSuffix, key, isFuture) => {
        return isFuture ? t('inMin', { number }) : t('minAgo', { number });
      },
      mm: (number, withoutSuffix, key, isFuture) => {
        return isFuture ? t('inMins', { number }) : t('minsAgo', { number });
      },
      h: (number, withoutSuffix, key, isFuture) => {
        return isFuture ? t('inHour', { number }) : t('hrAgo', { number: 1 });
      },
      hh: (number, withoutSuffix, key, isFuture) => {
        return isFuture ? t('inHours', { number }) : t('hoursAgo', { number });
      },
      d: (number, withoutSuffix, key, isFuture) => {
        return isFuture ? t('inDay', { number }) : t('dayAgo', { number });
      },
      dd: (number, withoutSuffix, key, isFuture) => {
        return isFuture ? t('inDays', { number }) : t('daysAgo', { number });
      },
      M: (number, withoutSuffix, key, isFuture) => {
        return isFuture ? t('inMonth', { number }) : t('monthAgo', { number });
      },
      MM: (number, withoutSuffix, key, isFuture) => {
        const withSuffix = isFuture ? t('inMonths', { number }) : t('monthsAgo', { number });
        return withoutSuffix ? t('months', { number }) : withSuffix;
      },
      y: (number, withoutSuffix, key, isFuture) => {
        return isFuture ? t('inYear', { number }) : t('yearAgo', { number });
      },
      yy: (number, withoutSuffix, key, isFuture) => {
        return isFuture ? t('inYears', { number }) : t('yearsAgo', { number });
      },
    },
  });
};

/**
 * Get the timezone offset in GMT format
 * @returns {string} the formatted timezone string
 */
export function getTimezoneString() {
  const offset = moment().utcOffset() / 60;
  const offsetStr = (offset >= 0 ? '+' : '-') + offset.toString().padStart(2, '0');
  return 'GMT' + offsetStr;
}

export const getFormattedDateRange = ({ from, to }) => {
  if (!from && !to) return null;
  const startDate = moment.utc(from).local().format('D MMM YY • HH:mm');
  const diffDays = moment.utc(to).diff(moment.utc(from), 'days');

  let endDate;
  if (diffDays > 0) {
    endDate = moment.utc(to).local().format('D MMM YY • HH:mm');
  } else {
    endDate = moment.utc(to).local().format('HH:mm');
  }

  return [startDate, endDate].join(' - ');
};

export const getLogDateTimeFormatter = (value) => moment.utc(value).local().format(FORMAT_DATE_TIME_FOR_LOGS);

function pad(number, length) {
  let str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}

export const getCurrentTimeZone = () => {
  let offset = new Date().getTimezoneOffset();
  offset =
    (offset < 0 ? '+' : '-') + // Note the reversed sign!
    pad(parseInt(Math.abs(offset / 60)), 2) +
    pad(Math.abs(offset % 60), 2);
  return offset;
};
