import '../../styles/components/item.scss';

import { useState } from 'react';
import { Trans } from 'react-i18next';

import { isObjectEmpty } from '../../infrastructure';
import Item from './index';

const ItemAccordion = ({ index, item, isItemTrackingNumberShown, adminAccess, isItemLogShown }) => {
  const [isItemDetailsShown, setIsItemDetailsShown] = useState(isItemLogShown);

  const toggleExpandItemDetails = () => {
    setIsItemDetailsShown(!isItemDetailsShown);
  };

  if (isObjectEmpty(item)) {
    return null;
  }

  return (
    <div className="item-accordion" data-cy="tracking-item-details">
      <div className="top" data-cy="tracking-item-header">
        <div className="head">
          <div className="left-side">
            <h4 data-cy="tracking-item-no-index">
              <Trans>Item</Trans> {index + 1}
            </h4>
            {isItemTrackingNumberShown ? (
              <div className="item-tracking-number-container" data-cy="tracking-item-number">
                <label>
                  <Trans>Item No</Trans>:&nbsp;
                </label>
                <span className="item-tracking-number">
                  {adminAccess['items.show.external_customer_id'] && item.external_customer_id
                    ? item.external_customer_id
                    : item.tracking_number}
                </span>
              </div>
            ) : null}
          </div>

          <div className="right-side">
            <div
              data-cy="tracking-item-collapse-toggle"
              onClick={toggleExpandItemDetails}
              className={(isItemDetailsShown ? 'collapse ' : 'expand ') + ' toggle-item-details'}
            />
          </div>
        </div>
      </div>
      <div className="bottom" data-cy="tracking-item-body">
        <Item
          index={index}
          item={item}
          isItemTrackingNumberShown={true}
          isItemLogShown={isItemLogShown}
          isItemDetailsShown={isItemDetailsShown}
        />
      </div>
    </div>
  );
};

export default ItemAccordion;
