import Axios from 'axios';

import { addVersionHeader } from '@yojee/api/common/versionHeaderHelper';

import { BaseService } from './baseService';

export class AuthService extends BaseService {
  get = (url, params, options) => {
    return Axios.get(url, { params, ...this.createAuthRequestConfig(options) });
  };
  post = (url, body, options) => {
    return Axios.post(url, body, this.createAuthRequestConfig(options));
  };
  put = (url, body, options) => {
    return Axios.put(url, body, this.createAuthRequestConfig(options));
  };
  delete = (url, options) => {
    return Axios.delete(url, this.createAuthRequestConfig(options));
  };

  createAuthRequestConfig = (options) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    };

    const slug = localStorage.getItem('TRACKING-PAGE-COMPANY_SLUG');
    const accessToken = localStorage.getItem('TRACKING-PAGE-ACCESS_TOKEN');

    if (accessToken) {
      headers['ACCESS_TOKEN'] = accessToken;
    }
    if (slug) {
      headers['company_slug'] = slug;
    }

    if (options) {
      const { authorization, senderOrganisationSlug } = options;
      if (authorization) {
        headers['Authorization'] = authorization;
      }
      if (senderOrganisationSlug) {
        headers['sender_organisation_slug'] = senderOrganisationSlug;
      }
    }

    const config = { headers, cancelToken: options?.axiosCancelToken };
    addVersionHeader(config);
    return config;
  };
}

export const authService = new AuthService();
