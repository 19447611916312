import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TASK_STATES } from '@yojee/helpers/stepHelpers';
import { ETA_STATUSES, getStatusOfETA } from '@yojee/helpers/TasksHelper';
import { getTimezoneString } from '@yojee/helpers/TimeHelper';

import { convertToKebabCase, getDestinationTask, ITEM_STATUSES } from '../../infrastructure';
import {
  externalOrderIdTitleSelector,
  isItemExternalCustomerIdShownSelector,
  isSenderInfoAndAddressShownSelector,
  isTaskEtaShownSelector,
  itemExternalCustomerIdTitleSelector,
} from '../../sagas/company/companySelectors';
import {
  orderExternalIdSelector,
  originalOrganisationNameSelector,
  senderTypeSelector,
} from '../../sagas/order/orderSelectors';

const DATE_TIME_FORMAT = 'DD MMM YYYY - HH:mm';
const timeZoneString = getTimezoneString();

const ItemTopInfo = ({ item, itemStatus }) => {
  const isItemExternalCustomerIdShown = useSelector(isItemExternalCustomerIdShownSelector);
  const externalOrderIdTitle = useSelector(externalOrderIdTitleSelector);
  const itemExternalCustomerIdTitle = useSelector(itemExternalCustomerIdTitleSelector);
  const isTaskEtaShown = useSelector(isTaskEtaShownSelector);
  const externalOrderId = useSelector(orderExternalIdSelector);
  const originalOrganisationName = useSelector(originalOrganisationNameSelector);
  const senderType = useSelector(senderTypeSelector);
  const isSenderInfoAndAddressShown = useSelector(isSenderInfoAndAddressShownSelector);
  const isETAShown = useSelector((state) => !!state.company?.companyInfo?.copy['task.show.eta']);

  const renderNextTaskEta = () => {
    // Check if there is any uncompleted task
    const unCompletedTask = item.tasks?.find((task) => task.state !== TASK_STATES.completed);

    if (!unCompletedTask || !unCompletedTask.eta) return null;

    // Check if the ETA is in the past
    const etaTime = moment(unCompletedTask.eta);
    if (etaTime.isBefore(moment())) return null;

    return (
      <div className="eta-container" data-cy="next-task-item-eta">
        <Trans>Next Task ETA:</Trans>
        <span className="eta">
          {etaTime.format(DATE_TIME_FORMAT)} ({timeZoneString})
        </span>
      </div>
    );
  };

  const renderEtaRow = () => {
    if (!isTaskEtaShown) return null;

    const sortedTasks = [...item.tasks].sort((a, b) => a.step_sequence - b.step_sequence);
    const destinationTask = getDestinationTask(sortedTasks);

    let eta = null,
      etaStatus = '';
    if (
      destinationTask &&
      [
        ITEM_STATUSES.processing,
        ITEM_STATUSES.outForPickup,
        ITEM_STATUSES.inTransit,
        ITEM_STATUSES.outForDelivery,
      ].includes(itemStatus)
    ) {
      eta = destinationTask.eta ? moment.utc(destinationTask.eta).local().format(DATE_TIME_FORMAT) : '';
      etaStatus =
        destinationTask.eta && destinationTask.eta_updated_at
          ? getStatusOfETA(moment.utc(destinationTask.eta), moment.utc(destinationTask.eta_updated_at))
          : null;
    }
    const isGoodETA = etaStatus === ETA_STATUSES.IN_TODAY_AND_RECENTLY_UPDATED;

    return (
      eta &&
      etaStatus &&
      etaStatus !== ETA_STATUSES.IN_PAST && (
        <div className={`eta-container ${isGoodETA ? 'healthy-eta' : ''}`} data-cy="expected-delivery-on-eta">
          <Trans>Expected delivery on</Trans>:{' '}
          <span className="eta">
            {eta} ({timeZoneString})
          </span>
          <Tooltip
            title={
              isGoodETA ? (
                <Trans>This is a Live ETA, but please note that the ETA is an estimation</Trans>
              ) : (
                <Trans>Please note that the ETA is an estimation</Trans>
              )
            }
          >
            <InfoOutlinedIcon className="eta-information-icon" />
          </Tooltip>
        </div>
      )
    );
  };

  return (
    <div className="item-top-info-container">
      <div className="item-main-info-container" data-cy="tracking-item-main-info">
        <div className={`status-icon-container ${convertToKebabCase(itemStatus)}`} />
        <div className="item-tracking-info-container">
          <div className="status-container" data-cy="tracking-item-progress-status">
            <div className={`status ${convertToKebabCase(itemStatus)}`}>{itemStatus}</div>
          </div>
          {externalOrderId && (
            <div className="tracking-number-container" data-cy="tracking-external-order-id">
              {externalOrderIdTitle} <span className="tracking-number">{externalOrderId}</span>
            </div>
          )}
          <div className="tracking-number-container" data-cy="tracking-item-id">
            {isItemExternalCustomerIdShown && item.external_customer_id ? (
              itemExternalCustomerIdTitle
            ) : (
              <Trans>Item Tracking Number</Trans>
            )}
            &nbsp;
            <span className="tracking-number">
              {isItemExternalCustomerIdShown && item.external_customer_id
                ? item.external_customer_id
                : item.tracking_number}
            </span>
          </div>
          {item?.item?.item_container?.container_no && (
            <div className="tracking-number-container" data-cy="tracking-item-container-number">
              <Trans>Container Number</Trans>&nbsp;
              <span className="tracking-number">{item?.item?.item_container?.container_no}</span>
            </div>
          )}
          {isETAShown && renderNextTaskEta()}
          {isETAShown && renderEtaRow()}
        </div>
      </div>
      {senderType !== 'individual' && originalOrganisationName && (
        <div className={`sender-info-container ${!isSenderInfoAndAddressShown ? 'one-line' : ''}`}>
          <div className="sender-name">
            <Trans>Sender</Trans>: {originalOrganisationName}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemTopInfo;
