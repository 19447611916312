import partnerWorkerIcon from './partner_worker_smaller.svg';
import workerBlueIcon from './worker_blue_smaller.svg';
import workerLightBlueIcon from './worker_light_blue_smaller.svg';
import workerPurpleIcon from './worker_purple_smaller.svg';

export const images = {
  workerActive: workerBlueIcon,
  workerIsNotOnDuty: workerLightBlueIcon,
  workerIsHavingOnGoingTask: workerPurpleIcon,
  partnerWorker: partnerWorkerIcon,
};
