import { STEP_PROPERTY_TYPES, TASK_GROUP_STATES, TASK_STATES } from '@yojee/helpers/stepHelpers';

import { OrderItem, Task } from '../../types';

export enum ITEM_STATUSES {
  cancelled = 'Cancelled',
  processing = 'Processing',
  outForPickup = 'Out for Pickup',
  inTransit = 'In Transit',
  outForDelivery = 'Out for Delivery',
  delivered = 'Delivered',
}

export const getItemCurrentStatus = (item: OrderItem): ITEM_STATUSES => {
  if (isItemCancelled(item)) {
    return ITEM_STATUSES.cancelled;
  }

  const sortedTasks = [...item.tasks].sort((a, b) => a.step_sequence - b.step_sequence);
  if (isItemProcessing(sortedTasks)) {
    return ITEM_STATUSES.processing;
  }
  if (isItemOutForPickup(sortedTasks)) {
    return ITEM_STATUSES.outForPickup;
  }
  if (isItemInTransit(sortedTasks)) {
    return ITEM_STATUSES.inTransit;
  }
  if (isItemOutForDelivery(sortedTasks)) {
    return ITEM_STATUSES.outForDelivery;
  }
  if (isItemDelivered(sortedTasks)) {
    return ITEM_STATUSES.delivered;
  }

  return ITEM_STATUSES.processing;
};

export const isItemCancelled = (item: OrderItem) => {
  return !!item['cancelled_at'];
};

export const isItemProcessing = (orderedTasks: Task[]) => {
  const originTask = getOriginTask(orderedTasks);

  if (originTask) {
    return (
      originTask.state !== TASK_STATES.completed &&
      (!originTask.task_group.state ||
        originTask.task_group.state === TASK_GROUP_STATES.unassigned ||
        (originTask.task_group.state === TASK_GROUP_STATES.assigned && !originTask.task_group.accepted_time))
    );
  }

  return false;
};

export const isItemOutForPickup = (orderedTasks: Task[]) => {
  const originTask = getOriginTask(orderedTasks);

  if (originTask) {
    return (
      originTask.task_group.state === TASK_GROUP_STATES.assigned &&
      !!originTask.task_group.accepted_time &&
      originTask.state !== TASK_STATES.completed
    );
  }

  return false;
};

export const isItemInTransit = (orderedTasks: Task[]) => {
  const destinationTask = getDestinationTask(orderedTasks);
  const nextStep = orderedTasks.find((task) => task.state !== TASK_STATES.completed);

  if (nextStep) {
    return nextStep !== destinationTask && destinationTask?.state !== TASK_STATES.completed;
  }

  return false;
};

export const isItemOutForDelivery = (orderedTasks: Task[]) => {
  const destinationTask = getDestinationTask(orderedTasks);

  if (destinationTask) {
    const taskBeforeDestinationTask = orderedTasks[orderedTasks.indexOf(destinationTask) - 1];
    return taskBeforeDestinationTask?.state === TASK_STATES.completed && destinationTask.state === TASK_STATES.created;
  }

  return false;
};

export const isItemDelivered = (orderedTasks: Task[]) => {
  return getDestinationTask(orderedTasks)?.state === TASK_STATES.completed;
};

export const getOriginTask = (orderedTasks: Task[]) => {
  return (
    orderedTasks.find((task) => task.step_property === STEP_PROPERTY_TYPES.origin) ||
    orderedTasks.find((step) => step.type === 'pickup')
  );
};

export const getDestinationTask = (orderedTasks: Task[]) => {
  return (
    orderedTasks.find((task) => task.step_property === STEP_PROPERTY_TYPES.destination) ||
    [...orderedTasks].reverse().find((task) => task.type === 'dropoff')
  );
};
